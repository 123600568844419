import request from '@/utils/request'


// 查询客服人员列表
export function listPerson(query) {
  return request({
    url: '/customer/person/list',
    method: 'get',
    params: query
  })
}

// 查询客服人员详细
export function getPerson(data) {
  return request({
    url: '/customer/person/detail',
    method: 'get',
    params: data
  })
}

// 新增客服人员
export function addPerson(data) {
  return request({
    url: '/customer/person/add',
    method: 'post',
    data: data
  })
}

// 修改客服人员
export function updatePerson(data) {
  return request({
    url: '/customer/person/edit',
    method: 'post',
    data: data
  })
}

// 删除客服人员
export function delPerson(data) {
  return request({
    url: '/customer/person/delete',
    method: 'post',
    data: data
  })
}
